// TODO: https://medium.com/@rupesh94/how-to-encrypt-localstorage-data-in-angular-270ebcbc1435
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as cryptoJS from 'crypto-js';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientStorageService {
  KEYS = {
    USER: 'currentUser',
    TOKEN: 'token',
    SELECTED_LANGUAGE: 'sltLanguage',
    SELECTED_WORKSPACE: 'activeWorkspace',
    USER_PERMISSIONS: 'userPermissions',
    SELECTED_LIST: 'listId',
    ANALYSIS_FILTERS: 'analysisFilters',
    LISTID: 'listId',
    INTERVALS: 'intervals',
    REFRESHTOKEN: 'refreshToken',
    METHOD_OF_AUTH: 'authMethod',
    TABLE_ROWS: 'tableRows',
    TABLE_FIRST: 'tableFirst',
    THEME: 'currentTheme',
    INCIDENT_FILTER: 'incident_filter'
  };

  SAVE_KEYS = [this.KEYS.SELECTED_LANGUAGE, this.KEYS.METHOD_OF_AUTH, this.KEYS.THEME];

  private storage = localStorage;
  private sessionStorage = sessionStorage;

  public getValue(key: string): any {
    const securityKey = this.encode(environment.securityCode + key + environment.apiUrl);
    const value = this.storage.getItem(securityKey) || null;
    if (!value) {
      return value;
    }
    const decryptValue = this.decrypt(securityKey, value);
    const parsedValue = JSON.parse(decryptValue);
    return parsedValue?.text || parsedValue;
  }

  public setValue(key: string, value: {} | number | string): void {
    var val = _.cloneDeep(value);
    const securityKey = this.encode(environment.securityCode + key + environment.apiUrl);
    const encryptValue = this.encrypt(securityKey, val);
    return this.storage.setItem(securityKey, encryptValue);
  }

  public setSessionValue(key: string, value: {} | number | string): void {
    const securityKey = this.encode(environment.securityCode + key + environment.apiUrl);
    const encryptValue = this.encrypt(securityKey, value);
    return this.sessionStorage.setItem(securityKey, encryptValue);
  }

  public getSessionValue(key: string): any {
    const securityKey = this.encode(environment.securityCode + key + environment.apiUrl);
    const value = this.sessionStorage.getItem(securityKey) || null;
    if (!value) {
      return value;
    }
    const decryptValue = this.decrypt(securityKey, value);
    const parsedValue = JSON.parse(decryptValue);
    return parsedValue?.text || parsedValue;
  }

  public valueExists(key: string): boolean {
    const securityKey = this.encode(environment.securityCode + key + environment.apiUrl);
    return this.storage.hasOwnProperty(securityKey);
  }

  public clear(): void {
    Object.keys(this.KEYS)
      .filter(
        (key: string) => !this.SAVE_KEYS.some((saveKey: string) => saveKey === this.KEYS[key])
      )
      .forEach((key: string) => {
        this.removeItem(this.KEYS[key]);
      });
  }

  public removeItem(key: string): void {
    const securityKey = this.encode(environment.securityCode + key + environment.apiUrl);
    this.storage.removeItem(securityKey);
  }

  private encrypt(key: string, text: {} | number | string): string {
    return cryptoJS.AES.encrypt(JSON.stringify({ text }), JSON.stringify(key)).toString();
  }

  private decrypt(key: string, text: string) {
    return cryptoJS.AES.decrypt(text, JSON.stringify(key)).toString(cryptoJS.enc.Utf8);
  }

  private encode(str: string): string {
    const encodedWord = cryptoJS.enc.Utf8.parse(str);
    const encoded = cryptoJS.enc.Base64.stringify(encodedWord);
    return encoded;
  }
}
