import { AuthenticationService } from '@app/auth/services/authentication.service';
import { NavigationItems, Workspace } from '@app/shared/models/user.interface';
import { Component, OnInit, ViewChild } from '@angular/core';

import { UserProfile } from '@app/shared/models/user.interface';
import { Router, ActivatedRoute, NavigationEnd, NavigationSkipped } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ServerRouteAdapter } from '@app/auth/services/server-route-adapter.service';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';
import { LocationKey } from '@app/shared/models/location-key.enum';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { findTreeNodeRecursion } from '@app/shared/utils/find-tree-node.recursion';
import { findLastItemInTreeNode } from '@app/shared/utils/find-last-item-tree-node';
import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import { Breadcrumb } from '../models/breadcrumbs.interface';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { PwaService } from '@app/shared/services/pwa.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.less'],
  providers: [ConfirmationService],
})
export class TopNavComponent extends UnsubscriberComponent implements OnInit {
  menuData: any;
  item: any;
  subitem: any;
  title: string;
  // menu
  menuOptions: MenuItem[];
  tab_items: MenuItem[];
  navigationItems: NavigationItems[] = [];
  items: MenuItem[];
  display: boolean;
  workspaces: Workspace[];
  elements: any[];
  touched: boolean;
  breadcrumbs: Breadcrumb[];
  userProfileData: NavigationItems;
  isRedirecting: boolean = false;
  @ViewChild('downloadPromotion') downloadPromotion: OverlayPanel;

  constructor(
    public router: Router,
    private authenticationservice: AuthenticationService,
    private authenticationRestService: AuthenticationRESTService,
    private route: ActivatedRoute,
    private serverRouteAdapter: ServerRouteAdapter,
    private breadcrumbsService: BreadcrumbsService,
    private pwaService: PwaService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadUserProfile();
    this.subscribeOnRouteEvents();
  }

  navigate(event: MouseEvent): void {
    if (!event.ctrlKey)
      this.isRedirecting = true;
  }

  selectTab(node): void {
    if(node.children && node.children.length){
      node.expanded = !node.expanded;
    }else{
      this.isRedirecting = true;
      const workspaceId = localStorage.getItem('activeWorkspace').replace(/"/g, "");
      this.toggleSideBar();
      this.router.navigate([`workspaces/${workspaceId}/${node.routerLink.replace(/_/g, '/')}`]);
    }
  }

  // LOAD DATA FROM THE PROFILE INTO LOCAL VARIABLES
  loadUserProfile(): void {
    const user = this.authenticationservice.getUserProfile();
    if (user) {
      this.menuOptions = this.transformWorkspacesToItems(user.profileNavigation.items);
      this.tab_items = this.transformModulesToTabs(user.navigation.items);
      this.workspaces = user.workspaces || [];
      this.userProfileData = user.profileNavigation;
    } else {
      this.navigationItems = [];
      this.menuOptions = [];
      this.workspaces = user.workspaces;
    }
  }

  subscribeOnRouteEvents(): void {
    this.breadcrumbs = this.breadcrumbsService
      .getBreadcrumbs(this.route.root)
      .reduce((acc, x) => acc.concat(acc.find((y) => y.url === x.url) ? [] : [x]), []);
    this.router.events
      .pipe(
        takeUntil(this.$destroy),
        tap((e) => {
          if (e instanceof NavigationSkipped) {
            this.isRedirecting = false;
          }
        }),
        filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isRedirecting = false;
        this.breadcrumbs = this.breadcrumbsService
          .getBreadcrumbs(this.route.root)
          .reduce((acc, x) => acc.concat(acc.find((y) => y.url === x.url) ? [] : [x]), []);
      });
  }

  // PARSE DATA FROM API TO NGPRIME CLASSES
  transformWorkspacesToItems(_workspaces: NavigationItems[]): MenuItem[] {
    let items: MenuItem[] = [];
    items = [];
    _workspaces.forEach((s) => {
      const item = { ...s, label: s.title, routerLink: s.locationKey, id: s.locationKey.slice(-1) };
      items.push(item);
    });
    return items;
  }
  toggleSideBar(): void {
    this.display = !this.display;
  }
  // PARSE DATA FROM API TO NGPRIME CLASSES
  transformModulesToTabs(_modules: NavigationItems[], level: number = 0): MenuItem[] {
    const items: MenuItem[] = [];
    _modules.forEach((s) => {
      if (s?.items?.length) {
        s.children = this.transformModulesToTabs(s.items, level + 1);
        delete s.items;
      }
      if (!s?.isDynamicObjectTab) {
        items.push({
          label: s.title,
          routerLink: this.serverRouteAdapter.mapRoutingKeyToActualRouting(
            s.locationKey as LocationKey
          ),
          ...s,
          styleClass: level.toString() + 'L',
        });
      }
    });
    return items;
  }

  routeMe(route: string): string {
    switch (route) {
      case 'usersettings':
        return 'helper/usersettings';
      case 'logout':
        return '/auth/sign-in';
      case 'incident':
        return 'incidents';
      case 'task':
        return 'tasks';
      case 'organization':
        return 'organizations';
      default:
        return route;
    }
  }

  optionClicked(value: { option: any, event: MouseEvent }): void {
    if (!value.event.ctrlKey && value.option.locationKey != "help")
      this.isRedirecting = true;
    this.toggleSideBar();
    if (value.option.routerLink.includes('workspaces/')) {
      const defaultRedirectUrl = `/workspaces/${value.option.id}`;
      const workspace = this.workspaces.find((el) => el.workspaceId == value.option.id);
      const navigationStart = workspace ? workspace.navigationStart : '';
      this.authenticationRestService
        .getUserByWorkspaceId(value.option.id)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          next: (value: any) => {
            const navigation = findTreeNodeRecursion(
              value?.navigation,
              navigationStart,
              'locationKey',
              'items'
            );
            const lastItem = findLastItemInTreeNode(navigation, 'items');
            window.location.href = `${defaultRedirectUrl}/${this.serverRouteAdapter.mapRoutingKeyToActualRouting(
              lastItem?.locationKey as LocationKey
            )}`;
          },
        });
      return;
    }

    switch (value.option.routerLink) {
      case 'help':
        this.authenticationRestService.getHelpUrl().subscribe((url) => {
          window.open(url);
        });
        break;
      case 'logout':
        this.router.navigate(['auth/logout']);
        break;
      default:
        const url = this.router.serializeUrl(
          this.router.createUrlTree([this.routeMe(value.option.routerLink)], { relativeTo: this.route })
        );
        this.router.navigate([this.routeMe(value.option.routerLink)], { relativeTo: this.route });
        break;
    }
  }

  logout(): void {
    this.authenticationRestService.logout(true);
  }

}
