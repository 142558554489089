import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnSet } from '@app/shared/models/column-set.interface';
import { ListType } from '@app/shared/models/list-type.enum';
import { FieldModel } from '@app/step-builder/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableCustomizerService {

  private columnSets: ColumnSet[];
  private columnSetChanged: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public columnSetChanged$ = this.columnSetChanged.asObservable();

  constructor(private router: Router) { }

  set _columnSets(columnSets: ColumnSet[]) {
    this.columnSets = columnSets;
  }

  getLocalStorageData(): any {
    return JSON.parse(localStorage.getItem('columnSet'));
  }

  setLocalStorageData(localStorageData: any): void {
    localStorage.setItem('columnSet', JSON.stringify(localStorageData));
  }

  getActiveColumnSet(location: ListType): number {
    const localStorageData = this.getLocalStorageData();
    if (!!localStorageData && 
        localStorageData[location] && 
        localStorageData[location].activeColumnSetId) {
      const activeColumnSet = this.columnSets.find((columnSet) => 
        columnSet.id === localStorageData[location].activeColumnSetId);
      return this.columnSets.indexOf(activeColumnSet) || 0;
    } else {
      return 0;
    }
  } 

  mapAttributes(location: ListType, attributes: FieldModel[], activeColumnSetId: number): FieldModel[] {
    const localStorageData = this.getLocalStorageData();
    if (!!localStorageData && 
        !!localStorageData[location] && 
        !!localStorageData[location][activeColumnSetId]) {
      return attributes.map((attribute: FieldModel) => {
        attribute.visible = localStorageData[location][activeColumnSetId][attribute.property];
        return attribute;
      });
    } else {
      return attributes;
    }
  }

  setActiveColumnSetParams(activeColumnSetId: number): Promise<boolean> {
    return this.router.navigate([], { queryParams: { activeColumnSetId }, queryParamsHandling: 'merge' });
  }

  emitColumnSetChanged(columnSetId: number): void {
    this.columnSetChanged.next(columnSetId);
  }
}
