import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import { ProgressButtonService } from '@app/shared/services/progress-button.service';
import { fromEvent, merge } from 'rxjs';
import { auditTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.less']
})
export class ProgressButtonComponent extends UnsubscriberComponent implements OnInit, AfterViewInit {
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() barId: string;
  @Input() disabled: boolean = false;
  @Input() title: string;
  @Input() isArrayOfEntities: boolean = false;
  @Input() lengthOfArray: number;
  private activeRequest = 0;
  private progressBar: HTMLElement;
  isRequestInProgress = false;

  constructor(private progressButtonService: ProgressButtonService) {
    super();
  }

  ngAfterViewInit(): void {
    this.progressBar = document.getElementById(this.barId);
    fromEvent(this.progressBar, 'transitionend')
    .pipe(auditTime(700))
    .subscribe(() => {
      if(this.progressBar.style.width === '100%'){
        this.progressBar.style.transition = 'none';
        this.progressBar.style.width = '0';
      }
    });
  }

  ngOnInit(): void {
    merge(this.progressButtonService.progressBarUpdated, this.progressButtonService.progressBarReset)
    .pipe(takeUntil(this.$destroy))
    .subscribe((res: any) => {
      if(!!res.event && res.id ===  this.barId){
        this.updateProgressButton(res.event);
      }else if(typeof(res) === 'string' && res === this.barId){
        this.resetProgressBar();
      }
    });
  }

  updateProgressButton(event: HttpEvent<any> | any): void {
    if (this.isArrayOfEntities) {
      this.updateProgressButtonArrayMode();
      return;
    }
    switch (event.type){
      case 0: 
        this.progressBar.style.transition = 'all 0.5s';
        this.progressBar.style.width = '25%';
        this.progressBar.style.transition = 'all 5s';
        this.progressBar.style.width = '70%';
        break;
      case 1:
        this.progressBar.style.width = '90%';
        break;
      case HttpEventType.Response:
        this.progressBar.style.transition = 'all 0.2s';
        this.progressBar.style.width = '100%';
        break;
    }
  }

  updateProgressButtonArrayMode(): void {
    this.activeRequest++;
    const percents = (this.activeRequest / this.lengthOfArray) * 100;
    this.progressBar.style.width = `${percents}%`;
  }

  resetProgressBar(): void {
    
    this.progressBar.style.transition = 'none';
    setTimeout(() => {
      this.progressBar.style.width = '0';
      this.activeRequest = 0;
      this.isRequestInProgress = false;
    }, 1000);

  }

  onClick(): void {
    if (this.isRequestInProgress) {
      return;
    }

  this.isRequestInProgress = true;
  this.progressBar.style.width = '2%';
  this.clicked.emit();
  }
}
