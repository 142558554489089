import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Component({
  selector: 'app-markdown-help',
  templateUrl: './markdown-help.component.html',
  styleUrls: ['./markdown-help.component.less']
})
export class MarkdownHelpComponent implements OnInit {

  jsonData: any;

  constructor(private http: HttpClient) { }

  ngOnInit(): void { 
    this.loadJsonData();
  }

  loadJsonData(): void {
    this.http.get('assets/helper/markdown-help.json').subscribe(data => {
      this.jsonData = data;
    }, error => {
      console.error('Error loading JSON data:', error);
    });
  }

  onDragStart(event: any, characters: string): void {
    event.dataTransfer.setData('text/plain', characters);
  }
}
